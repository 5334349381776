<template lang="pug">
  DatePicker.datepicker(
    ref="datepicker"
    :lang="lang"
    v-bind="$props"
    v-on="$listeners"
    @click.native="handleClick"
    :open="open"
    @close="open = false"
  )
</template>

<script>
  // misc
  import "vue2-datepicker/index.css"
  import { DATE_FORMAT, ENTER_KEY_CODE, ESC_KEY_CODE } from "@/config/constants"

  const CLOSE_BUTTONS = [ESC_KEY_CODE, ENTER_KEY_CODE]
  const ICON_CLASSNAME = "mx-icon-calendar"

  export default {
    props: {
      value: [Date, Array],

      placeholder: String,

      range: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      disabledDate: {
        type: Function,
        default: () => false
      },

      format: {
        type: String,
        default: DATE_FORMAT
      },

      rangeSeparator: {
        type: String,
        default: " - "
      },

      clearable: {
        type: Boolean,
        default: true
      },

      appendToBody: {
        type: Boolean,
        default: true
      },

      type: {
        type: String,
        default: "date"
      }
    },

    components: {
      DatePicker: () => import("vue2-datepicker")
    },

    mounted() {
      document.addEventListener("keyup", this.handleKeyup)
    },

    beforeDestroy() {
      document.removeEventListener("keyup", this.handleKeyup)
    },

    computed: {
      lang() {
        return {
          monthBeforeYear: false,
          monthFormat: "MM",
          formatLocale: {
            weekdaysMin: [
              this.$t("datepicker.weekdays_min.sunday"),
              this.$t("datepicker.weekdays_min.monday"),
              this.$t("datepicker.weekdays_min.tuesday"),
              this.$t("datepicker.weekdays_min.wednesday"),
              this.$t("datepicker.weekdays_min.thursday"),
              this.$t("datepicker.weekdays_min.friday"),
              this.$t("datepicker.weekdays_min.saturday")
            ]
          }
        }
      }
    },

    data() {
      return {
        open: false
      }
    },

    methods: {
      handleKeyup(e) {
        if (CLOSE_BUTTONS.includes(e.keyCode)) {
          const datePicker = this.$refs.datepicker

          if (datePicker.popupVisible) this.closePopup()
        }
      },

      handleClick(e) {
        const target = e.target
        const datePicker = this.$refs.datepicker
        const isTargetIcon = target.className === ICON_CLASSNAME
        const isInputClicked = target.tagName === "INPUT"

        if (datePicker.popupVisible) {
          if (isTargetIcon) {
            this.closePopup()
          }
        } else {
          if (isInputClicked || isTargetIcon) {
            this.openPopup()
          }
        }
      },

      openPopup() {
        this.open = true
      },

      closePopup() {
        this.open = false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .datepicker
    padding-right: 10px

    ::v-deep
      .mx-input
        border: 1px solid $border-element-color
        box-shadow: none
        color: $default-black
        font-size: 0.8rem
        height: 38px
        letter-spacing: 0
        line-height: 26px

        &:disabled
          color: $default-gray
          opacity: 0.6

      .mx-icon-calendar
        color: $default-purple
        svg
          pointer-events: none
</style>
